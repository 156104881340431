@font-face {
  font-family: 'Raleway';
  src: url('../../fonts/raleway/Raleway-Light.eot');
  src: url('../../fonts/raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
  url("../../fonts/raleway/Raleway-Light.otf") format("opentype"), /* Open Type Font */
  url('../../fonts/raleway/Raleway-Light.ttf') format('truetype'),
  url('../../fonts/raleway/Raleway-Light.woff') format('woff'),
  url('../../fonts/raleway/Raleway-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../../fonts/raleway/Raleway-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/raleway/Raleway-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/raleway/Raleway-Regular.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/raleway/Raleway-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/raleway/Raleway-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../fonts/raleway/Raleway-Medium.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/raleway/Raleway-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/raleway/Raleway-Medium.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/raleway/Raleway-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/raleway/Raleway-Medium.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/raleway/Raleway-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../fonts/raleway/Raleway-SemiBold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/raleway/Raleway-SemiBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/raleway/Raleway-SemiBold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/raleway/Raleway-SemiBold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/raleway/Raleway-SemiBold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/raleway/Raleway-SemiBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../fonts/raleway/Raleway-Bold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/raleway/Raleway-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/raleway/Raleway-Bold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/raleway/Raleway-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/raleway/Raleway-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/raleway/Raleway-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../fonts/raleway/Raleway-Bold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/raleway/Raleway-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/raleway/Raleway-Bold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/raleway/Raleway-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/raleway/Raleway-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/raleway/Raleway-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url('../../fonts/raleway/Raleway-ExtraBold.eot');
  src: url('../../fonts/raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url("../../fonts/raleway/Raleway-ExtraBold.otf") format("opentype"), /* Open Type Font */
  url('../../fonts/raleway/Raleway-ExtraBold.ttf') format('truetype'),
  url('../../fonts/raleway/Raleway-ExtraBold.woff') format('woff'),
  url('../../fonts/raleway/Raleway-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "OpenSans";
  src: url("../../fonts/open-sans/OpenSans-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/open-sans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/open-sans/OpenSans-Regular.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/open-sans/OpenSans-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/open-sans/OpenSans-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/open-sans/OpenSans-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url("../../fonts/open-sans/OpenSans-Semibold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/open-sans/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/open-sans/OpenSans-Semibold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/open-sans/OpenSans-Semibold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/open-sans/OpenSans-Semibold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/open-sans/OpenSans-Semibold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/roboto/Roboto-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/roboto/Roboto-Regular.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/roboto/Roboto-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/roboto/Roboto-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/roboto/Roboto-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/roboto/Roboto-Bold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/roboto/Roboto-Bold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/roboto/Roboto-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/roboto/Roboto-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/roboto/Roboto-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}